<template>

	<!-- 팝업 진행 -->
	<sweet-modal ref="modal" title="사용자 기본 설정" blocking>
		<b-container style="overflow-y: auto; max-height: 400px">
			<div v-show="isLoding">
				<b-spinner></b-spinner>
				로딩중..
			</div>
			<div v-if="isLoding === false">
				<b-row>
					<b-col md="3">
						<label>선택 된 계정 : {{ data.length }}개</label>
					</b-col>
					<b-col md="9">
						<b-badge class="ml-1" variant="danger" v-for="(item,index) in data" :key="index">
							{{ item.account }}
						</b-badge>
					</b-col>
				</b-row>
				<b-row class="mb-2">
					<b-col md="4">
						<label>마스터 계정</label>
						<b-input v-model="masterName"></b-input>
					</b-col>
					<b-col md="4">
						<label>등급</label>
						<b-form-select v-model="planName" :options="planModel" class="form-control custom-select" />
					</b-col>
					<b-col md="4">
						<label>종료기간</label>
						<b-form-group id="example-date">
							<b-form-input id="date" v-model="expireDate" type="date"></b-form-input>
						</b-form-group>
					</b-col>
				</b-row>
			</div>
		</b-container>
		<b-row v-if="isLoding === false" class="text-right mr-0">
			<b-col md="12">
				<b-button variant="danger" @click="save" squared>수정</b-button>
			</b-col>
		</b-row>
	</sweet-modal>
</template>

<script>
import {SweetModal} from 'sweet-modal-vue'
import Swal from "sweetalert2";
import api from '@/api/admin'

export default {
	components: {
		SweetModal,
	},
	data() {
		return {
			data: null,
			isLoding: true,
			planModel: [],
			planName: "선택",
			masterName: null,
			expireDate: null
		}
	},
	methods: {
		async open(userModels, masterName, planModel) {
			this.isLoding = true
			this.data = userModels
			this.planName = "선택",
				this.planModel = [],
				this.expireDate = null,
				this.masterName = masterName
			this.planModel = planModel
			if (this.planModel.findIndex(x => x == "선택") == -1) {
				this.planModel.push('선택')
			}
			this.isLoding = false
			this.$refs.modal.open()
		},
		save() {
			if (this.planName == "선택") {
				Swal.fire('오류', '플랜을 선택 해 주세요.', 'warning')
				return
			} else if (this.expireDate == null) {
				Swal.fire('오류', '날짜를 선택 해 주세요.', 'warning')
				return
			}
			Swal.fire({
				title: '확인',
				text: "기본 설정을 변경를 진행하시겠습니까?",
				icon: 'question',
				showCancelButton: true,
				confirmButtonColor: 'primary',
				cancelButtonColor: 'dark',
				confirmButtonText: '변경',
				cancelButtonText: '취소'
			}).then(async (result) => {
				if (result.isConfirmed) {
					const postModel = {
						userId: this.data.map(x => x.account),
						setting: {
							master: this.masterName,
							planName: this.planName,
							expireDate: this.expireDate
						}
					}
					let responseModel = await api.SetUserBasicInfo(postModel)
					if (responseModel.status == "Success") {
						await this.$emit('Search')
						Swal.fire('성공', '성공하였습니다.', 'success')
						this.$refs.modal.close()
					} else {
						Swal.fire('오류', responseModel.message, 'warning')
					}
				}
			})
		}
	}
}
</script>