<template>

  <!-- 팝업 진행 -->
  <sweet-modal  ref="modal"  :title='userId+" 오픈마켓 설정"' blocking>
    <b-container style="overflow-y: auto; max-height: 400px">
      <div v-show="isLoding"><b-spinner></b-spinner>로딩중..</div>
      <div v-if="isLoding == false">
        <strong class="text-danger" v-show="data.length ==0">설정한 데이터가 존재하지않습니다.</strong>
        <b-row class="mb-2" v-for="(node,index) in data" :key="index">
          <b-col md="3">
            <b-input style="font-weight: 800" class="readOnlyStyle" :readonly="true" v-model="node.krName"></b-input>
          </b-col>
          <b-col md="2">
            <b-input style="font-weight: 800;" class="text-center readOnlyStyle" :readonly="true" v-model="node.marketIdx"></b-input>
          </b-col>
          <b-col md="5">
            <b-input style="font-weight: 800;" class="readOnlyStyle" :readonly="true" v-model="node.account"></b-input>
          </b-col>
          <b-col md="2">
            <b-button variant="danger" @click="removeMarket(node)">삭제</b-button>
          </b-col>
        </b-row>
      </div>
    </b-container>
  </sweet-modal>
</template>

<script>
import { SweetModal } from 'sweet-modal-vue'
import api from '@/api/admin'
import Swal from "sweetalert2";

export default {
  components: {
    SweetModal,
  },
  data(){
    return {
      data: [],
      isLoding: false,
      userId: null
    }
  },
  methods:{
    async removeMarket(data){
      if(this.userId == undefined){
        Swal.fire('오류','비정상적인 접근입니다.',"warning")
        return
      }

      Swal.fire({
        title: '확인',
        text: "선택하신 "+ data.krName + " 오픈마켓 설정 삭제를 진행하시겠습니까?",
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: 'primary',
        cancelButtonColor: 'dark',
        confirmButtonText: '변경',
        cancelButtonText: '취소'
      }).then(async (result) => {
        if (result.isConfirmed) {
          let postModel = {
            "userId": this.userId,
            "market": data.market,
            "account": data.account,
            "marketIdx": data.marketIdx,
          }
          let responseModel = await api.RemoveUserOpenMarket(postModel)
          if(responseModel.status=="Success") {
            await this.init()
            Swal.fire('성공','성공하였습니다.','success')
          }else{
            Swal.fire('오류',responseModel.message,'warning')
          }
        }
      })
    },
    async init(){
      this.isLoding = true
      let userModel = await api.GetUserOpenMarket(this.userId)
      if(userModel.status== "Success"){
        this.data = userModel.data
        this.data.forEach(x=> {
          switch (x.market) {
            case "SmartStore":
              x.krName = "스마트스토어";
              break;
            case "Coupang":
              x.krName = "쿠팡";
              break;
            case "Auction":
              x.krName = "옥션";
              break;
            case "Gmarket":
              x.krName = "지마켓";
              break;
            case "Elevenst":
              x.krName = "11번가";
              break;
            case "ElevenstGlobal":
              x.krName = "11번가 글로벌";
              break;
            case "Interpark":
              x.krName = "인터파크";
              break;
            case "LotteOn":
              x.krName = "롯데온";
              break;
            case "Tmon":
              x.krName = "티몬";
              break;
            case "WeMakePrice":
              x.krName = "위메프";
              break;
            case "SSG":
              x.krName = "신세계";
              break;
            default:
              x.krName = x.market;
              break;
          }
        })
        this.isLoding = false
        this.$refs.modal.open()
      }
      this.isLoding = false

    },
    async open(userId){
      this.isLoding = true
      this.userId = userId
      await this.init()
    },
    close(){
      this.$refs.modal.close()
    }
  }
}
</script>

<style scoped>

.readOnlyStyle {
  background-color: #6c757d;
  color:white;
}
</style>
