<template>
	<Layout>
		<PageHeader :title="title" :items="items"/>
		<div class="row">
			<div class="col-lg-12">
				<div class="card">
					<div class="card-body pt-0">
						<b-tabs nav-class="nav-tabs-custom">
							<b-tab title-link-class="p-3">
								<template v-slot:title>
									<a class="font-weight-bold active">사용자</a>
								</template>
								<div class="d-flex justify-content-between p-3">
									<div class="d-flex align-items-center">
										<div class="d-flex align-items-center mr-4" id="tickets-table_length">
											<label class="d-inline-flex align-items-center m-0">
												<b-form-select
													v-model="perPage" size="sm"
													:options="pageOptions" /> 명
											</label>
										</div>
										<div class="d-flex gap-1">
											<b-button
												size="sm" variant="outline-danger" class="mr-2"
												@click="multiDefaultSettingModalOpen">
												기본 설정
											</b-button>
											<b-button
												size="sm" variant="outline-danger" class="mr-2"
												@click="multiSosingSettingModalOpen">
												소싱사이트 설정
											</b-button>
											<b-button
												size="sm" variant="outline-danger" class="mr-2"
												@click="multiOpenmarketSettingModalOpen">
												오픈마켓 설정
											</b-button>
											<b-button
												size="sm" variant="outline-danger" class="mr-2"
												@click="multiExpireSettingModal">
												만료일시 일괄변경
											</b-button>
										</div>
									</div>
									<!-- Search -->
									<div class="d-flex">
										<b-button size="sm" variant="primary" class="mr-3" @click="excelDown">
											엑셀 다운로드
										</b-button>
										<div id="tickets-table_filter" class="dataTables_filter text-md-right">
											<label class="d-inline-flex align-items-center my-0">
												Search:
												<b-form-input
													v-model="searchValue"
													type="search"
													class="form-control form-control-sm ml-2"
													@keypress.enter="Search"
												/>
											</label>
										</div>
									</div>
									<!-- End search -->
								</div>
								<div class="table-responsive">
									<b-table
										class="table-centered"
										:items="data"
										:fields="fields"
										responsive="sm"
										:current-page="currentPage"
										:busy="isLoad"
									>
										<template #table-busy>
											<div class="text-center text-danger my-2">
												<b-spinner class="align-middle"></b-spinner>
												<strong>로딩중...</strong>
											</div>
										</template>
										<template #head(isCheck)>
											<b-checkbox v-model="isAllCheck" :value=true :unchecked-value=false @change="allCheck" />
										</template>
										<template #cell(isCheck)="data">
											<b-checkbox v-model="data.item.isCheck" :value=true :unchecked-value=false />
										</template>

										<template #cell(joinDate)="data">
											{{ $moment(data.item.joinDate).format('YY-MM-DD hh:mm') }}
										</template>
										<template #cell(expireDate)="data">
											{{ $moment(data.item.expireDate).format('YY-MM-DD hh:mm') }}
										</template>
										<template #cell(action)="data">
											<div>
												<a
													href="javascript:void(0);"
													class="mr-3 text-dark"
													v-b-tooltip.hover
													title="소싱사이트 설정"
													@click="sosingSettingModalOpen(data.item)"
												>
													<i class="mdi mdi-web-box font-size-18"></i>
												</a>

												<a
													href="javascript:void(0);"
													class="mr-3 text-success"
													v-b-tooltip.hover
													title="오픈마켓 제한 설정"
													@click="openmarketSettingModalOpen(data.item)"
												>
													<i class="mdi mdi-shopping-search font-size-18"></i>
												</a>

												<a
													href="javascript:void(0);"
													class="mr-3 text-primary"
													v-b-tooltip.hover
													title="오픈마켓 삭제 설정"
													@click="UserCollectModalOpen(data.item.account)"
												>
													<i class="mdi mdi-pencil font-size-18"></i>
												</a>
												<a
													href="javascript:void(0);"
													class="text-danger"
													v-b-tooltip.hover
													title="사용자 설정"
													@click="UserModalOpen(data.item)"
												>
													<i class="mdi mdi-account font-size-18"></i>
												</a>
											</div>
										</template>
										<template #cell(planName)="data">
											<b-badge
												class="p-2 text-center" :class="{ 'bg-primary': data.item.planName === '대기중'}">
												{{ data.item.planName }}
											</b-badge>
										</template>
									</b-table>
								</div>
								<div class="row">
									<div class="col">
										<div class="dataTables_paginate paging_simple_numbers float-right">
											<ul class="pagination pagination-rounded mb-0">
												<!-- pagination -->
												<b-pagination
													@page-click="PageMove"
													:total-rows="totalRows"
													:per-page="perPage"
													v-model="currentPage"
												/>
											</ul>
										</div>
									</div>
								</div>
							</b-tab>
						</b-tabs>
					</div>
				</div>
			</div>
		</div>
		<sosing-setting-modal ref="sosingSettingModal" />
		<openmarket-setting-modal ref="openmarketSettingModal" />
		<user-collect-modal ref="userCollectModal" />
		<user-modal ref="userModal" @Search="Search" />
		<multi-default-setting-modal ref="multiDefaultSettingModal" @Search="Search" />
		<multi-sosing-setting-modal ref="multiSosingSettingModal" @Search="Search" />
		<multi-openmarket-setting-modal ref="multiOpenmarketSettingModal" @Search="Search" />
		<multi-expire-setting-modal ref="multiExpireSettingModal" @Search="Search" />
	</Layout>
</template>

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import api from "@/api/admin"
import Swal from "sweetalert2";
import UserCollectModal from "./Modal/UserCollectModal";
import UserModal from "./Modal/UserModal";
import OpenmarketSettingModal from './Modal/OpenmarketSettingModal';
import MultiDefaultSettingModal from "./MultiModal/MultiDefaultSettingModal";
import MultiSosingSettingModal from "./MultiModal/MultiSosingSettingModal";
import MultiOpenmarketSettingModal from "./MultiModal/MultiOpenmarketSettingModal";
import MultiExpireSettingModal from './MultiModal/MultiExpireSettingModal';
import SosingSettingModal from "./Modal/SosingSettingModal";

export default {
	components: {
		SosingSettingModal,
		OpenmarketSettingModal,
		MultiOpenmarketSettingModal,
		MultiSosingSettingModal,
		MultiDefaultSettingModal,
		MultiExpireSettingModal,
		UserModal,
		UserCollectModal,
		Layout,
		PageHeader
	},
	data() {
		return {
			title: "사용자 관리",
			items: [
				{
					text: "관리자모드"
				},
				{
					text: "사용자 관리",
					active: true
				}
			],
			planModel: [],
			data: null,
			isLoad: false,
			searchValue: '',
			totalRows: 10000,
			currentPage: 1,
			perPage: 10,

			pageOptions: [10, 30, 50, 100, 1000],
			filter: null,
			filterOn: [],
			sortBy: "no",
			sortDesc: false,
			isAllCheck: false,
			fields: [
				{key: "isCheck", label: ""},
				{key: "no", sortable: true, label: "ID"},
				{key: "recommender", sortable: true, label: "추천인"},
				{key: "master", sortable: true, label: "마스터 계정"},
				{key: "account", sortable: true, label: "사용자 계정"},
				{key: "userName", sortable: true, label: "사용자"},
				{key: "agencyName", sortable: true, label: "상호명"},
				{key: "planName", sortable: true, label: "플랜명"},
				{key: "businessRegistration", sortable: true, label: "사업자번호"},
				{key: "contactTelNo", sortable: true, label: "전화번호"},
				{key: "email", sortable: true, label: "이메일"},
				{key: "joinDate", sortable: true, label: "가입일시"},
				{key: "expireDate", sortable: true, label: "만료일시"},
				{key: "action", sortable: true, label: "기능"},
			]
		};
	},
	async created() {
		let planModel = await api.GetPlan()
		if (planModel.status  ===  "Success") {
			this.planModel = planModel.data
		} else {
			Swal.fire('오류', '플랜정보를 가져오는데 실패하였습니다. 새로고침 후 사용부탁드립니다.');
		}
		await this.Search()
	},
	methods: {
		async Search() {
			this.isAllCheck = false
			this.data = null;
			let postModel = {
				"type": "ALL",
				"value": this.searchValue,
				"pageNum": this.currentPage,
				"maxRow": this.perPage
			}
			if (this.searchValue !== '') {
				postModel.type = "SEARCH"
			}
			this.isLoad = true
			let responseModel = await api.GetUserList(postModel)
			if (responseModel.status  ===  "Success") {
				this.totalRows = responseModel.data.maxPage * this.perPage
				this.data = responseModel.data.userList
				for (let i = 0; i < this.data.length; i++) {
					let isCheck = false
					this.data[i] = {...this.data[i], isCheck}
				}

			} else {
				this.totalRows = 1
				this.data = []
			}
			this.isLoad = false
		},
		async PageMove(button, page) {
			this.currentPage = page;
			await this.Search();
		},
		async UserModalOpen(model) {
			if (model.account  ===  undefined) {
				await Swal.fire('오류', '계정이 존재하지않습니다.', 'warning')
				return
			}
			this.$refs.userModal.open(model, this.planModel)
		},
		async sosingSettingModalOpen(model) {
			if (model.account  ===  undefined) {
				await Swal.fire('오류', '계정이 존재하지않습니다.', 'warning')
				return
			}
			this.$refs.sosingSettingModal.open(model)
		},
		async openmarketSettingModalOpen(model) {
			if (model.account  ===  undefined) {
				await Swal.fire('오류', '계정이 존재하지않습니다.', 'warning')
				return
			}
			this.$refs.openmarketSettingModal.open(model)
		},
		async UserCollectModalOpen(account) {
			if (account  ===  undefined) {
				await Swal.fire('오류', '계정이 존재하지않습니다.', 'warning')
				return
			}
			this.$refs.userCollectModal.open(account)
		},
		async taobaoCountUpdate(data) {
			Swal.fire({
				title: '확인',
				text: "선택하신 " + data.account + " 타오바오 개수를 " + data.taobaoCount + "개로 변경 하시겠습니까?",
				icon: 'question',
				showCancelButton: true,
				confirmButtonColor: 'primary',
				cancelButtonColor: 'dark',
				confirmButtonText: '변경',
				cancelButtonText: '취소'
			}).then(async (result) => {
				if (result.isConfirmed) {
					let postModel = [
						{
							"account": data.account,
							"count": data.taobaoCount
						}
					]
					let responseModel = await api.SetUserTaobaoCount(postModel)
					if (responseModel.status  ===  "Success") {
						Swal.fire('성공', '성공하였습니다.', 'success')
					} else {
						Swal.fire('오류', responseModel.message, 'warning')
					}
					await this.Search()
				}
			})
		},
		allCheck(key) {
			this.data.forEach(x => x.isCheck = key)
		},
		multiOpenmarketSettingModalOpen() {
			let checkModels = this.data.filter(x => x.isCheck  ===  true)
			if (checkModels.length  ===  0) {
				Swal.fire('오류', '사용자를 선택해 주세요.', 'error')
				return
			}
			this.$refs.multiOpenmarketSettingModal.open(checkModels)
		},
		multiSosingSettingModalOpen() {
			let checkModels = this.data.filter(x => x.isCheck  ===  true)
			if (checkModels.length  ===  0) {
				Swal.fire('오류', '사용자를 선택해 주세요.', 'error')
				return
			}
			this.$refs.multiSosingSettingModal.open(checkModels)
		},

		multiDefaultSettingModalOpen() {
			let checkModels = this.data.filter(x => x.isCheck  ===  true)
			if (checkModels.length  ===  0) {
				Swal.fire('오류', '사용자를 선택해 주세요.', 'error')
				return
			}
			let masterNames = checkModels.map(x => x.master)
			masterNames = [...new Set(masterNames)]
			if (masterNames.length > 1) {
				Swal.fire('오류', '마스터 계정이 동일하지 않습니다.', 'error')
				return
			}
			this.$refs.multiDefaultSettingModal.open(checkModels, masterNames[0], this.planModel)
		},
		multiExpireSettingModal() {
			const checkItems = this.data.filter((item) => item.isCheck);
			if (checkItems.length === 0) {
				Swal.fire('오류', '사용자를 선택해 주세요.', 'error');
				return;
			}
			this.$refs.multiExpireSettingModal.open(checkItems);
		},
		excelDown() {
			if (this.data.length  ===  0) {
				Swal.fire({
					title: '경고',
					text: '사용자 정보가 존재하지않습니다.',
					icon: 'warning'
				})
				return
			}
			let excel = []
			this.data.forEach(x => {
				const excelLogs = {
					"no": x.no,
					"추천인": x.recommender,
					"마스터 계정": x.master,
					"사용자 계정": x.account,
					"사용자": x.userName,
					"상호명": x.agencyName,
					"플랜명": x.planName,
					"사업자번호": x.businessRegistration,
					"전화번호": x.contactTelNo,
					"이메일": x.email,
					"가입일시": x.joinDate,
					"만료일시": x.expireDate
				};
				excel.push(excelLogs)
			})
			const today = new Date();
			const xlsx = require('xlsx');
			let excelData = xlsx.utils.json_to_sheet(excel); // table id를 넣어주면된다
			let workBook = xlsx.utils.book_new(); // 새 시트 생성
			xlsx.utils.book_append_sheet(workBook, excelData, '사용자');  // 시트 명명, 데이터 지정
			xlsx.writeFile(workBook, '사용자정보_' + today.toISOString() + '.xlsx'); // 엑셀파일 만듬
		}
	},
	watch: {
		perPage(val) {
			this.perPage = val
			this.Search(this.searchValue)
		}
	}
}
</script>
