<template>

  <!-- 팝업 진행 -->
  <sweet-modal ref="modal"  title="사용자 설정" blocking>
    <b-container style="overflow-y: auto; max-height: 400px">
      <div v-show="isLoding"><b-spinner></b-spinner>로딩중..</div>
      <div v-if="isLoding == false">
        <b-row class="mb-2">
          <b-col md="3">
            <label>사용자 계정</label>
            <b-input :readonly="true" class="readOnlyStyle" v-model="data.account"></b-input>
          </b-col>
          <b-col md="3">
            <label>이름</label>
            <b-input v-model="data.userName"></b-input>
          </b-col>
          <b-col md="6">
            <label>가입기간</label>
            <b-input :readonly="true" class="readOnlyStyle" v-model="data.joinDate"></b-input>
          </b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col md="4">
            <label>마스터 계정</label>
            <b-input v-model="data.master"></b-input>
          </b-col>
          <b-col md="8">
            <label>이메일</label>
            <b-input v-model="data.email"></b-input>
          </b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col md="5">
            <label>상호명</label>
            <b-input v-model="data.agencyName"></b-input>
          </b-col>
          <b-col md="7">
            <label>사업자번호</label>
            <b-input v-model="data.businessRegistration"></b-input>
          </b-col>
        </b-row>
        <b-row>
        <b-col md="4">
          <label>등급</label>
          <b-form-select  v-model="data.planName" :options="planModel" class="form-control custom-select" ></b-form-select>
        </b-col>
        <b-col md="5">
          <label>종료기간</label>
          <b-form-group
              id="example-date"
          >
            <b-form-input id="date" v-model="data.expireDateType1" type="date"></b-form-input>
          </b-form-group>

        </b-col>
        <b-col md="3">
          <b-button block style="margin-top:27px" variant="danger" @click="save">수정</b-button>
        </b-col>
      </b-row>
      </div>
    </b-container>
  </sweet-modal>
</template>

<script>
import { SweetModal } from 'sweet-modal-vue'
import Swal from "sweetalert2";
import api from '@/api/admin'

export default {
  components: {
    SweetModal,
  },
  data(){
    return {
      data: null,
      planModel: [],
      isLoding: true,
    }
  },
  methods:{
    async save(){
      Swal.fire({
        title: '확인',
        text: "사용자 설정 변경를 진행하시겠습니까?",
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: 'primary',
        cancelButtonColor: 'dark',
        confirmButtonText: '변경',
        cancelButtonText: '취소'
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.data.expireDate = this.data.expireDateType1
          let responseModel = await api.SetUserInfo(this.data)
          if(responseModel.status=="Success") {
            await this.$emit('Search')
            Swal.fire('성공','성공하였습니다.','success')
            this.$refs.modal.close()
          }else{
            Swal.fire('오류',responseModel.message,'warning')
          }

        }
      })
    },
    async open(data,planModel){
      this.isLoding = true
      this.data = data
      this.planModel = planModel
      this.data.expireDateType1 = this.$moment(data.expireDate).format('YYYY-MM-DD')
      this.isLoding = false
      this.$refs.modal.open()
    },
    close(){
      this.$refs.modal.close()
    }
  }
}
</script>

<style scoped>
.readOnlyStyle {
  background-color: #6c757d;
  color:white;
}
</style>
