<template>

  <!-- 팝업 진행 -->
  <sweet-modal ref="modal"  title="소싱사이트 제한" blocking>
    <b-container style="overflow-y: auto; max-height: 400px">
      <div v-show="isLoding"><b-spinner></b-spinner>로딩중..</div>
      <div v-if="isLoding == false">
        <b-row class="mb-2">
          <b-col md="4">
            <label>알리익스프레스 KR</label>
            <b-input v-model='aliexpress_kr'></b-input>
          </b-col>
          <b-col md="4">
            <label>아마존 US</label>
            <b-input v-model='amazon_us'></b-input>
          </b-col>
          <b-col md="4">
            <label>타오바오</label>
            <b-input v-model='taobao'></b-input>
          </b-col>
        </b-row>
      </div>
    </b-container>
    <b-row v-if="isLoding== false" class="text-right">
      <b-col md="12">
        <b-button style="margin-right:12px" variant="danger" @click="save" squared>수정</b-button>
      </b-col>
    </b-row>
  </sweet-modal>
</template>

<script>
import { SweetModal } from 'sweet-modal-vue'
import Swal from "sweetalert2";
import api from '@/api/admin'

export default {
  components: {
    SweetModal,
  },
  data(){
    return {
      data: null,
      isLoding: true,
      aliexpress_kr: -1,
      amazon_us: -1,
      taobao:40000
    }
  },
  methods:{
    async init(){

    },
    async open(userModels){
      this.isLoding = true
      this.aliexpress_kr= -1,
      this.amazon_us= -1,
      this.taobao=40000,
      this.data = userModels
      this.isLoding = false
      this.$refs.modal.open()
    },
    async save(){
      Swal.fire({
        title: '확인',
        text: "소싱사이트 설정을 변경를 진행하시겠습니까?",
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: 'primary',
        cancelButtonColor: 'dark',
        confirmButtonText: '변경',
        cancelButtonText: '취소'
      }).then(async (result) => {
        if (result.isConfirmed) {
          let postModel = []
          this.data.forEach(x => {
            postModel.push({
              userId: x.account,
              aliexpress_kr: this.aliexpress_kr,
              amazon_us: this.amazon_us,
              taobao: this.taobao
            })
          })
          let responseModel = await api.SetUserSourcingSiteLimit(postModel)
          if (responseModel.status == "Success") {
            await this.$emit('Search')
            Swal.fire('성공', '성공하였습니다.', 'success')
            this.$refs.modal.close()
          } else {
            Swal.fire('오류', responseModel.message, 'warning')
          }
        }
      })
    }
  }
}
</script>

<style scoped>
.readOnlyStyle {
  background-color: #6c757d;
  color:white;
}
</style>
