<template>

  <!-- 팝업 진행 -->
  <sweet-modal ref="modal"  title="오픈마켓 제한" blocking>
    <b-container style="overflow-y: auto; max-height: 400px">
      <div v-show="isLoding"><b-spinner></b-spinner>로딩중..</div>
      <div v-if="isLoding == false">
        <b-row class="mb-2">
          <b-col md="4">
            <label>스마트스토어</label>
            <b-input v-model='data.smartStore'></b-input>
          </b-col>
          <b-col md="4">
            <label>쿠팡</label>
            <b-input v-model='data.coupang'></b-input>
          </b-col>
          <b-col md="4">
            <label>옥션</label>
            <b-input v-model='data.auction'></b-input>
          </b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col md="4">
            <label>지마켓</label>
            <b-input v-model='data.gmarket'></b-input>
          </b-col>
          <b-col md="4">
            <label>11번가</label>
            <b-input v-model='data.elevenst'></b-input>
          </b-col>
          <b-col md="4">
            <label>11번가 글로벌</label>
            <b-input v-model='data.elevenstGlobal'></b-input>
          </b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col md="4">
            <label>인터파크</label>
            <b-input v-model='data.interpark'></b-input>
          </b-col>
          <b-col md="4">
            <label>위메프</label>
            <b-input v-model='data.weMakePrice'></b-input>
          </b-col>
          <b-col md="4">
            <label>티몬</label>
            <b-input v-model='data.tmon'></b-input>
          </b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col md="4">
            <label>롯데온</label>
            <b-input v-model='data.lotteOn'></b-input>
          </b-col>
        </b-row>
      </div>
    </b-container>
    <b-row v-if="isLoding== false" class="text-right">
      <b-col md="12">
        <b-button style="margin-right:12px" variant="danger" @click="save" squared>수정</b-button>
      </b-col>
    </b-row>
  </sweet-modal>
</template>

<script>
import { SweetModal } from 'sweet-modal-vue'
import Swal from "sweetalert2";
import api from '@/api/admin'

export default {
  components: {
    SweetModal,
  },
  data(){
    return {
      data: null,
      isLoding: true,
      smartstore: 1,
      coupang: 1,
      auction: 1,
      gmarket: 1,
      interpark: 1,
      elevenst: 4,
      elevenstGlobal: 4,
      weMakePrice: 1,
      tmon: 1,
      lotteOn: 1
    }
  },
  methods:{
    async init(userId){
      let result =false
      let responseModel = await api.GetUserOpenMarketLimit(userId)
      if(responseModel.status =="Success"){
        result = true
        this.data = responseModel.data
      }
      return result
    },
    async open(userModel){
      this.isLoding = true
      let isInit = await this.init(userModel.account)
      if(isInit == false){
        Swal.fire('오류','API 정보를 불러오는데 실패하였습니다.','error')
        return
      }
      this.isLoding = false
      this.$refs.modal.open()
    },
    async save(){
      Swal.fire({
        title: '확인',
        text: "오픈마켓 설정을 변경를 진행하시겠습니까?",
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: 'primary',
        cancelButtonColor: 'dark',
        confirmButtonText: '변경',
        cancelButtonText: '취소'
      }).then(async (result) => {
        if (result.isConfirmed) {
          let responseModel = await api.SetUserOpenMarketLimit([this.data])
          if (responseModel.status == "Success") {
            await this.$emit('Search')
            Swal.fire('성공', '성공하였습니다.', 'success')
            this.$refs.modal.close()
          } else {
            Swal.fire('오류', responseModel.message, 'warning')
          }
        }
      })
    }
  }
}
</script>

<style scoped>
.readOnlyStyle {
  background-color: #6c757d;
  color:white;
}
</style>
