<template>
  <sweet-modal ref="modal" title="만료일시 일괄변경" blocking>
    <b-container>
      <div v-show="isLoading">
        <b-spinner>로딩 중...</b-spinner>
      </div>
      <div v-if="!isLoading">
        <b-row class="align-items-center mb-3">
          <span class="mr-5"> 선택된 계정: {{ data.length }}개 </span>
          <div>
            <b-badge
              class="mr-2"
              variant="danger"
              v-for="(item, index) in data"
              :key="index"
            >
              {{ item.account }}
            </b-badge>
          </div>
        </b-row>
        <b-row class="justify-content-center align-items-center mb-3">
          <b-button class="font-size-20 primary" @click="addDate--">-</b-button>
          <input
            class="inputNumber"
            type="number"
            v-model="addDate"
            :style="{ width: String(addDate).length * 22 + 'px' }"
          />
          <b-button class="font-size-20 primary" @click="addDate++">+</b-button>
        </b-row>
        <b-row class="justify-content-end">
          <b-button variant="danger" @click="save" squared>수정</b-button>
        </b-row>
      </div>
    </b-container>
  </sweet-modal>
</template>

<script>
import { SweetModal } from "sweet-modal-vue";
import Swal from "sweetalert2";
import api from "@/api/admin";
export default {
  name: "MultiExpireSettingModal",
  components: {
    SweetModal,
  },
  data() {
    return {
      data: null,
      isLoading: true,
      addDate: 0,
    };
  },
  created() {
    console.log(String(this.addDate).length * 20);
  },
  methods: {
    async open(checkItems) {
      this.isLoading = false;
      this.addDate = 0;
      this.data = checkItems;
      this.$refs.modal.open();
    },
    async save() {
      Swal.fire({
        title: "확인",
        text: "만료일자를 변경하시겠습니까?",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "primary",
        cancelButtonColor: "dark",
        confirmButtonText: "변경",
        cancelButtonText: "취소",
      }).then(async (result) => {
        if (result.isConfirmed) {
          let responseModel = await api.setUserExpireDate({
            userIds: this.data.map((item) => item.account),
            addDay: this.addDate,
          });

          if (responseModel.status === "Success") {
            await this.$emit("Search");
            Swal.fire("성공", "성공하였습니다.", "success");
            this.$refs.modal.close();
          } else {
            Swal.fire("오류", responseModel.message, "warning");
          }
        }
      });
    },
  },
};
</script>
<style scoped>
.inputNumber {
  margin: 0 20px;
  border: none;
  outline: none;
  font-size: 32px;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  appearance: none;
  margin: 0;
}
</style>
