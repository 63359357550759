export const menuItems = [
    {
        id: 1,
        label: "메뉴",
        isTitle: true
    },
    {
        id: 2,
        label: '사용자 관리',
        icon: 'ri-dashboard-line',
        badge: {
            variant: "success",
        },
        link: '/'
    }
]