<template>

  <!-- 팝업 진행 -->
  <sweet-modal ref="modal"  title="오픈마켓 제한" blocking>
    <b-container style="overflow-y: auto; max-height: 400px">
      <div v-show="isLoding"><b-spinner></b-spinner>로딩중..</div>
      <div v-if="isLoding == false">
        <b-row class="mb-2">
          <b-col md="4">
            <label>스마트스토어</label>
            <b-input v-model='smartStore'></b-input>
          </b-col>
          <b-col md="4">
            <label>쿠팡</label>
            <b-input v-model='coupang'></b-input>
          </b-col>
          <b-col md="4">
            <label>옥션</label>
            <b-input v-model='auction'></b-input>
          </b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col md="4">
            <label>지마켓</label>
            <b-input v-model='gmarket'></b-input>
          </b-col>
          <b-col md="4">
            <label>11번가</label>
            <b-input v-model='elevenst'></b-input>
          </b-col>
          <b-col md="4">
            <label>11번가 글로벌</label>
            <b-input v-model='elevenstGlobal'></b-input>
          </b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col md="4">
            <label>인터파크</label>
            <b-input v-model='interpark'></b-input>
          </b-col>
          <b-col md="4">
            <label>위메프</label>
            <b-input v-model='weMakePrice'></b-input>
          </b-col>
          <b-col md="4">
            <label>티몬</label>
            <b-input v-model='tmon'></b-input>
          </b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col md="4">
            <label>롯데온</label>
            <b-input v-model='lotteOn'></b-input>
          </b-col>
        </b-row>
      </div>
    </b-container>
    <b-row v-if="isLoding== false" class="text-right">
      <b-col md="12">
        <b-button style="margin-right:12px" variant="danger" @click="save" squared>수정</b-button>
      </b-col>
    </b-row>
  </sweet-modal>
</template>

<script>
import { SweetModal } from 'sweet-modal-vue'
import Swal from "sweetalert2";
import api from '@/api/admin'

export default {
  components: {
    SweetModal,
  },
  data(){
    return {
      data: null,
      isLoding: true,
      smartStore: 1,
      coupang: 1,
      auction: 1,
      gmarket: 1,
      interpark: 1,
      elevenst: 1,
      elevenstGlobal: 1,
      weMakePrice: 1,
      tmon: 1,
      lotteOn: 1
    }
  },
  methods:{
    async open(userModels){
      this.isLoding = true
      this.smartStore= 1,
      this.coupang= 1,
      this.auction= 1,
      this.gmarket= 1,
      this.interpark= 1,
      this.elevenst= 1,
      this.elevenstGlobal= 1,
      this.weMakePrice= 1,
      this.tmon= 1,
      this.lotteOn= 1
      this.data = userModels
      this.isLoding = false
      this.$refs.modal.open()
    },
    async save(){
      Swal.fire({
        title: '확인',
        text: "오픈마켓 설정을 변경를 진행하시겠습니까?",
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: 'primary',
        cancelButtonColor: 'dark',
        confirmButtonText: '변경',
        cancelButtonText: '취소'
      }).then(async (result) => {
        if (result.isConfirmed) {
          let postModel = []
          this.data.forEach(x => {
            postModel.push({
              userId: x.account,
              smartStore: this.smartStore,
              coupang: this.coupang,
              auction: this.auction,
              gmarket: this.gmarket,
              interpark: this.interpark,
              elevenst: this.elevenst,
              elevenstGlobal: this.elevenstGlobal,
              weMakePrice: this.weMakePrice,
              tmon: this.tmon,
              lotteOn: this.lotteOn
            })
          })
          let responseModel = await api.SetUserOpenMarketLimit(postModel)
          if (responseModel.status == "Success") {
            await this.$emit('Search')
            Swal.fire('성공', '성공하였습니다.', 'success')
            this.$refs.modal.close()
          } else {
            Swal.fire('오류', responseModel.message, 'warning')
          }
        }
      })
    }
  }
}
</script>

<style scoped>
.readOnlyStyle {
  background-color: #6c757d;
  color:white;
}
</style>
